var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contract"},[_c('sign',{attrs:{"title":"Filcoin Fund Alliance"}}),_c('div',{staticClass:"content"},[_c('ul',{staticClass:"title-list"},_vm._l((_vm.titleList),function(item){return _c('li',{key:item.id,class:{
          'title-list-item': 'title-list-item',
          'active-title-list-item':
            _vm.currentTitle === item.id ? 'active-title-list-item' : '',
        },on:{"click":function($event){return _vm.handleChange(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"select-level"},[_vm._l((_vm.productList),function(item){return _c('div',{key:item.id,class:{
          option: 'option',
          'active-option': _vm.currentOption === item.id ? 'active-option' : '',
        },on:{"click":function($event){return _vm.handleOption(item)}}},[_vm._v(" "+_vm._s(item.usdt)+" FIL ")])}),_c('div',{staticClass:"option"})],2),_c('div',{staticClass:"day-income"},[_vm._m(0),_c('p',{staticClass:"income-amount"},[_vm._v(_vm._s(_vm.everyDayGain)+" FIL")])]),_c('img',{staticClass:"contarct-bg",attrs:{"src":require("../../assets/images/contract-bg.png"),"alt":""}}),_c('van-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('button',{staticClass:"buy-btn",on:{"click":_vm.handleBuy}},[_vm._v("购买")]),_c('button',{staticClass:"buy-btn",on:{"click":_vm.handleDeposit}},[_vm._v("赎回")])])],1),_c('van-dialog',{attrs:{"className":"dialog-pwd","title":"输入密码","confirm-button-color":"#27CC90","confirm-button-text":"支付","cancel-button-color":"#9AAABF","cancel-button-text":"取消","show-cancel-button":""},on:{"confirm":_vm.onConfirm},model:{value:(_vm.isShowDialog),callback:function ($$v) {_vm.isShowDialog=$$v},expression:"isShowDialog"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"pay-pwd-input",attrs:{"type":"password","placeholder":"请输入您的支付密码~"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('router-link',{staticClass:"forget-pwd",attrs:{"to":"/set_pwd?type=1"}},[_vm._v("忘记密码? ")])],1),_c('verify-code',{attrs:{"visiable":_vm.visiable,"verifyImg":_vm.verifyCodeImg},on:{"close":_vm.handleClose,"click":_vm.handleSubmit,"refersh":_vm.getVerifyCodeImg},model:{value:(_vm.verifyCode),callback:function ($$v) {_vm.verifyCode=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"verifyCode"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"divider"}),_vm._v(" 预计每天收益 ")])}]

export { render, staticRenderFns }