<template>
  <div class="introduce">
    <div class="title">
      <div class="left">
        <img src="../../assets/images/fil.png" alt="" />
        Filecoin $ {{ info["current_price"] }}
      </div>
      <p class="rate">
        {{ Number(info["price_change_percentage_24h"]).toFixed(2) }}%
      </p>
    </div>
    <p class="sub-title">总市值:$ {{ info["market_cap"] }}</p>
    <div class="content">
      <div class="left">
        <div class="mark">
          <p>市场占有率</p>
          <p class="number">
            {{ Number(info["market_cap_percentage"] * 100).toFixed(4) }}%
          </p>
        </div>
        <div class="mark">
          <p>总市值排名</p>
          <p class="number">{{ info["market_cap_rank"] }}</p>
        </div>
      </div>
      <div class="right">
        <div class="mark">
          <p>流通量</p>
          <p class="number">{{ info["total_supply"] }}</p>
        </div>
        <div class="mark">
          <p>价格</p>
          <p class="number">{{ info["full_diluted_valuation"] }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { marketInfo } from "../../server";

export default {
  name: "Introduce",
  data() {
    return {
      info: {},
    };
  },
  async created() {
    this.info = await marketInfo();
  },
};
</script>
<style lang="less" scoped>
.introduce {
  width: 363px;
  height: 203px;
  background-image: url("../../assets/images/introduct-bg.png");
  background-size: cover;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;

  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: "PingFang SC";
      font-weight: bold;
      color: #ffffff;

      img {
        width: 24px;
        height: 24px;
        margin-right: 5px;
      }
    }

    .rate {
      width: 63px;
      height: 21px;
      background: #27a17c;
      opacity: 1;
      border-radius: 5px;
      font-size: 12px;
      font-family: "PingFang SC";
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      color: #e9ecf0;
    }
  }

  .sub-title {
    margin-top: 10px;
    font-size: 14px;
    font-family: "PingFang SC";
    font-weight: 400;
    color: #27cc90;
  }

  .content {
    box-sizing: border-box;
    padding-top: 12px;
    display: flex;
    align-items: center;

    .left,
    .right {
      width: 50%;
      height: 91px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;

      .mark {
        font-size: 14px;
        font-family: "PingFang SC";
        font-weight: 500;
        color: #ffffff;

        .number {
          margin-top: 15px;
        }
      }

      .mark:nth-child(2) {
        margin-top: 10px;
      }
    }
  }
}
</style>
