<template>
  <div class="contract">
    <sign title="Filcoin Fund Alliance" />
    <div class="content">
      <ul class="title-list">
        <li
          @click="handleChange(item)"
          :class="{
            'title-list-item': 'title-list-item',
            'active-title-list-item':
              currentTitle === item.id ? 'active-title-list-item' : '',
          }"
          v-for="item in titleList"
          :key="item.id"
        >
          {{ item.name }}
        </li>
      </ul>
      <div class="select-level">
        <div
          @click="handleOption(item)"
          :class="{
            option: 'option',
            'active-option': currentOption === item.id ? 'active-option' : '',
          }"
          v-for="item in productList"
          :key="item.id"
        >
          {{ item.usdt }} FIL
        </div>
        <div class="option"></div>
      </div>
      <div class="day-income">
        <div class="left">
          <div class="divider"></div>
          预计每天收益
        </div>
        <p class="income-amount">{{ everyDayGain }} FIL</p>
      </div>
      <img
        class="contarct-bg"
        src="../../assets/images/contract-bg.png"
        alt=""
      />
      <van-row type="flex" justify="space-between">
        <button @click="handleBuy" class="buy-btn">购买</button>
        <button @click="handleDeposit" class="buy-btn">赎回</button>
      </van-row>
    </div>
    <van-dialog
      className="dialog-pwd"
      v-model="isShowDialog"
      title="输入密码"
      confirm-button-color="#27CC90"
      confirm-button-text="支付"
      cancel-button-color="#9AAABF"
      cancel-button-text="取消"
      show-cancel-button
      @confirm="onConfirm"
    >
      <input
        class="pay-pwd-input"
        type="password"
        placeholder="请输入您的支付密码~"
        v-model="password"
      />
      <router-link to="/set_pwd?type=1" class="forget-pwd"
        >忘记密码?
      </router-link>
    </van-dialog>
    <verify-code
      :visiable="visiable"
      @close="handleClose"
      @click="handleSubmit"
      @refersh="getVerifyCodeImg"
      v-model.trim="verifyCode"
      :verifyImg="verifyCodeImg"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Sign from "../../components/Sign.vue";
import VerifyCode from "../../components/VerifyCode";
import {
  buyMiner,
  getBalance,
  getVerifyCode,
  minerBuyList,
} from "../../server";
import _ from "lodash";

export default {
  components: { Sign, VerifyCode },
  name: "Contract",
  data() {
    return {
      password: "",
      isShowDialog: false,
      currentTitle: 1,
      currentMinerType: 0,
      titleList: [
        { id: 1, name: "个人基金" },
        { id: 2, name: "社区基金" },
      ],
      minerList: [],
      buyNumber: 1,
      filBalance: 0,
      currentOption: "",
      verifyCode: "",
      visiable: false,
      verifyCodeImg: "",
      whichFn: 0, // 0-购买 1-赎回
    };
  },
  computed: {
    ...mapState({ userInfo: (state) => state.common.userInfo }),
    productList() {
      return this.minerList.filter(
        (item) => item.minerType === this.currentTitle
      );
    },
    everyDayGain() {
      let gainAmount = 0;
      const currentMinerOption = this.minerList.filter(
        (item) => item.id === this.currentOption
      )[0];
      switch (currentMinerOption.usdt) {
        case 2:
          gainAmount = 0.0033;
          break;
        case 10:
          gainAmount = 0.033;
          break;
        case 20:
          gainAmount = 0.1;
          break;
        case 50:
          gainAmount = 0.3333;
          break;
        case 75:
          gainAmount = 0.625;
          break;
        default:
          gainAmount = 0;
          break;
      }
      return gainAmount;
    },
  },
  methods: {
    async handleBuy() {
      const needPay = this.productList.filter(
        (item) => item.id === this.currentOption
      )[0];
      if (this.filBalance < needPay.usdt) return this.$toast("FIL余额不足");
      this.visiable = true;
    },
    async handleDeposit() {
      this.$router.push("/buy_detail");
    },
    getVerifyCodeImg: _.throttle(async function () {
      this.verifyCodeImg = await getVerifyCode(this.getAddress, 4);
    }, 2000),
    async handleSubmit() {
      if (!this.verifyCode) return this.$toast("请输入验证码");

      await buyMiner(this.getAddress, this.verifyCode, this.currentOption);

      this.$toast("购买成功");

      this.visiable = false;
    },
    handleClose() {
      this.visiable = false;
    },
    handleOption(val) {
      this.currentOption = val.id;
    },
    handleChange(val) {
      this.currentTitle = val.id;
      this.currentOption = this.productList[0]?.id;
    },
    onConfirm() {
      console.log("购买成功");
    },
  },
  async created() {
    const data = await minerBuyList();
    this.getVerifyCodeImg();
    this.minerList = data;
    this.currentOption = data[0].id;
    this.filBalance = await getBalance(this.getAddress, "FIL");
  },
};
</script>

<style lang="less" scoped>
.contract {
  margin: 0 auto;
  box-sizing: border-box;
  padding: 15px;
  width: 345px;
  height: 521px;
  background: #161f2c;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.09);
  opacity: 1;
  border-radius: 10px;

  .content {
    box-sizing: border-box;
    padding-top: 14px;

    .title-list {
      width: 315px;
      height: 50px;
      background: #0f151f;
      box-shadow: 0px 0px 10px rgba(52, 52, 52, 0.08);
      opacity: 1;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 5px 15px;
      display: flex;
      align-items: center;

      .title-list-item {
        width: 153px;
        height: 40px;
        opacity: 1;
        font-size: 14px;
        font-family: "PingFang SC";
        font-weight: 500;
        line-height: 40px;
        text-align: center;
        color: #9aaabf;
      }

      .active-title-list-item {
        background: linear-gradient(90deg, #05c68b 0%, #019d6b 100%);
        box-shadow: 0px 3px 6px rgba(1, 153, 105, 0.25);
        border-radius: 10px;
        color: #ffffff;
      }
    }

    .select-level {
      height: 47px;
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;

      .option {
        width: 97px;
        height: 32px;
        background: #0f151f;
        opacity: 1;
        border-radius: 5px;
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 32px;
        text-align: center;
        color: #e9ecf0;
        margin-bottom: 10px;
      }

      .active-option {
        background: linear-gradient(90deg, #05c68b 0%, #019d6b 100%);
        box-shadow: 0px 3px 6px rgba(1, 153, 105, 0.25);
        color: #1e262f;
      }

      .option:last-child {
        visibility: hidden;
      }
    }

    .day-income {
      margin-top: 40px;
      margin-bottom: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: "PingFang SC";
        font-weight: 500;
        color: #ffffff;

        .divider {
          margin-right: 5px;
          width: 3px;
          height: 12px;
          background: linear-gradient(180deg, #05c68b 0%, #019d6b 100%);
          opacity: 1;
          border-radius: 2px;
        }
      }

      .income-amount {
        font-size: 14px;
        font-family: "PingFang SC";
        font-weight: 500;
        line-height: 20px;
        color: #27cc90;
      }
    }

    .contarct-bg {
      display: block;
      margin: 0 auto;
      width: 271px;
      height: 141px;
    }

    .buy-btn {
      margin-top: 36px;
      width: 153px;
      height: 45px;
      background: linear-gradient(90deg, #05c68b 0%, #019d6b 100%);
      box-shadow: 0px 3px 6px rgba(1, 153, 105, 0.25);
      opacity: 1;
      border-radius: 21px;
      border: none;
      font-size: 18px;
      font-family: "PingFang SC";
      font-weight: bold;
      line-height: 45px;
      text-align: center;
      color: #ffffff;
    }
  }

  .dialog-pwd {
    background-color: #161f2c;
    color: #e9ecf0;

    .pay-pwd-input {
      display: block;
      border: none;
      width: 265px;
      height: 34px;
      background: #0f151f;
      opacity: 1;
      border-radius: 5px;
      margin: 15px auto 6px;
      box-sizing: border-box;
      padding-left: 10px;
    }

    .pay-pwd-input::placeholder {
      color: #a2a2a2;
    }

    .forget-pwd {
      display: block;
      width: 100%;
      text-align: right;
      box-sizing: border-box;
      padding-right: 15px;
      padding-bottom: 10px;
      font-size: 12px;
      font-family: "PingFang SC";
      font-weight: 400;
      color: #27cc90;
    }

    /deep/ .van-button--default {
      background-color: #161f2c;
    }
  }
}
</style>
