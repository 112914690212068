<template>
  <div class="recharge">
    <sign title="Filcoin充值地址" />
    <div class="refer-card">
      <div class="coin">
        <h4 class="coin-title">币种</h4>
        <p class="coin-name">
          <img src="../../assets/images/fil.png" alt="" />
          FIL
        </p>
      </div>
      <div class="to-address">
        <span class="link-text">
          {{ linkUrl.slice(0, 14) + "***" + linkUrl.slice(-14) }}</span
        >
        <span
          v-clipboard:copy="linkUrl"
          v-clipboard:success="firstCopySuccess"
          v-clipboard:error="firstCopyError"
          class="iconfont iconfuzhi"
        ></span>
      </div>
      <div class="qrcode">
        <h4 class="qrcode-title">充值二维码</h4>
        <div ref="qrcodeRef" class="qrcode-img"></div>
      </div>
      <div class="info">
        <p class="min-recharge">最小充值 <span>2 FIL</span></p>
        <p class="tips">
          提示:充值上链时间取决于Filcoin网络，大约时间超过60秒，请等待。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { qrcanvas } from "qrcanvas";
import Sign from "../../components/Sign.vue";
import { mapState } from "vuex";
import { buyAddress } from "../../config/constants";
// import Qrcode from "qrcodejs2";
export default {
  name: "Rechagre",
  components: { Sign },

  computed: {
    ...mapState({ userInfo: (state) => state.common.userInfo }),
    linkUrl() {
      return buyAddress;
    },
  },
  methods: {
    // 初始化二维码
    initQrcode() {
      const canvas = qrcanvas({
        data: this.linkUrl,
        size: 64,
      });
      this.$refs.qrcodeRef.innerHTML = "";
      this.$refs.qrcodeRef.appendChild(canvas);
    },
    // 复制成功
    firstCopySuccess() {
      this.$toast("复制成功");
    },
    firstCopyError() {
      this.$toast("该设备不支持复制");
    },
  },
  mounted() {
    this.initQrcode();
  },
};
</script>

<style scoped lang="less">
.recharge {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 15px;

  .refer-card {
    margin-top: 14px;
    margin-bottom: 15px;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    width: 345px;
    height: 325px;
    background: #161f2c;
    opacity: 1;
    border-radius: 10px;

    .coin {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      color: #e9ecf0;
      font-family: "PingFang SC";

      .coin-title {
        font-size: 14px;
        font-weight: 400;
      }

      .coin-name {
        display: flex;
        align-items: center;
        width: 96px;
        height: 30px;
        background: #0f151f;
        opacity: 1;
        border-radius: 5px;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 10px;

        img {
          width: 18px;
          height: 18px;
          margin-right: 5px;
        }
      }
    }

    .to-address {
      margin-top: 14px;
      width: 315px;
      height: 50px;
      background: #0f151f;
      opacity: 1;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      .link-text {
        width: 254px;
        font-size: 14px;
        font-family: "PingFang SC";
        font-weight: 500;
        color: #27cc90;
        white-space: nowrap;
        overflow: hidden;
      }

      .iconfuzhi {
        margin-left: 10px;
        font-size: 12px;
        color: #27cc90;
      }
    }

    .qrcode {
      display: flex;
      align-items: center;
      flex-direction: column;

      h4 {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #e9ecf0;
        margin-top: 15px;
        margin-bottom: 13px;
      }

      .qrcode-img {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

    .info {
      height: 63px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-around;
      font-family: "PingFang SC";
      font-size: 12px;
      color: #e9ecf0;
      .min-recharge {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .min-recharge > span {
        color: #27cc90;
      }
      .tips {
        line-height: 20px;
      }
    }
  }
}
</style>
