<template>
  <div class="accounts">
    <sign title="账户信息" />
    <div class="content">
      <label class="myu-address">
        我的地址
        <input
          type="text"
          readonly
          :value="getAddress.slice(0, 19) + '****' + getAddress.slice(-8)"
        />
      </label>
      <div class="total">
        <div class="my-balance">
          <h4>我的余额</h4>
          <p class="amount">{{ balance }} FIL</p>
        </div>
        <div class="total-invest">
          <h4>总投入</h4>
          <p class="amount">{{ accounsList.investmentSum }} FIL</p>
        </div>
        <div class="total-profit">
          <h4>总收益</h4>
          <p class="amount">{{ accounsList.rewardSum }} FIL</p>
        </div>
        <div class="total-withdraw">
          <h4>总提现</h4>
          <p class="amount">{{ accounsList.withdrawSum }} FIL</p>
        </div>
      </div>
      <button class="account-btn" @click="$router.push('/withdraw')">
        提现
      </button>
    </div>
  </div>
</template>

<script>
import Sign from "../../components/Sign.vue";
import { getBalance, rewardSum } from "../../server/index";

export default {
  components: { Sign },
  name: "Accounts",
  data() {
    return {
      accounsList: {
        investmentSum: 0,
        rewardSum: 0,
        withdrawSum: 0,
      },
      balance: 0,
    };
  },
  methods: {
    async getFBalance() {
      const balanceInfo = await getBalance(this.getAddress, "FIL");
      this.balance = balanceInfo.available;
    },
  },
  async created() {
    this.accounsList = await rewardSum(this.getAddress);
    await this.getFBalance();
  },
};
</script>

<style lang="less" scoped>
.accounts {
  width: 100%;
  height: 332px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 15px;

  .content {
    margin-top: 14px;
    width: 345px;
    height: 249px;
    background: #161f2c;
    opacity: 1;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 15px;

    .myu-address {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-family: "PingFang SC";
      font-weight: 500;
      color: #e9ecf0;

      input {
        margin-left: 6px;
        width: 244px;
        height: 30px;
        background: #0f151f;
        opacity: 1;
        border-radius: 15px;
        border: none;
        font-size: 12px;
        font-family: "PingFang SC";
        font-weight: 500;
        text-align: center;
        color: #a2a2a2;
      }
    }

    .total {
      width: 100%;
      height: 120px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-shrink: 0;
      justify-content: space-around;
      flex-wrap: wrap;

      .total-invest,
      .total-profit,
      .my-balance,
      .total-withdraw {
        width: 50%;
        flex-shrink: 0;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        h4 {
          font-size: 16px;
          font-family: "PingFang SC";
          font-weight: 500;
          color: #e9ecf0;
        }

        .amount {
          margin-top: 5px;
          font-size: 18px;
          font-family: "PingFang SC";
          font-weight: bold;
          color: #27cc90;
        }
      }

      .total-withdraw,
      .total-invest {
        align-items: center;
      }
    }

    .account-btn {
      width: 315px;
      height: 45px;
      background: linear-gradient(90deg, #05c68b 0%, #019d6b 100%);
      box-shadow: 0px 3px 6px rgba(1, 153, 105, 0.25);
      opacity: 1;
      border-radius: 5px;
      border: none;
      font-size: 18px;
      font-family: "PingFang SC";
      font-weight: bold;
      line-height: 45px;
      text-align: center;
      color: #ffffff;
      margin-top: 15px;
    }
  }
}
</style>
